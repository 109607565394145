import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

// Utils
import { cn } from 'utils';
// Styles
import styles from './Accordion.module.css';

// Components
import { Picto } from '../Picto/Picto';
import RichTextContent from '../RichTextContent/RichTextContent';

function Accordion({
  item, isExpanded, handleExpanded, theme,
}) {
  return (
    <div className={cn([styles.item, styles[theme]])}>
      <motion.div
        initial={false}
        onClick={handleExpanded}
        className={styles.wrapper}
      >
        <span className={styles.container}>
          <h3 className={cn([styles.title, isExpanded ? styles.active : ''])}>{item?.title}</h3>
          <Picto icon={isExpanded ? 'minus' : 'plus'} className={styles.picto} />
        </span>
      </motion.div>
      <motion.div
        initial="initial"
        animate="open"
        exit="collapsed"
        variants={{
          initial: { height: 0, overflow: 'hidden' },
          collapsed: { height: 0, transition: { duration: 0.3 }, overflow: 'hidden' },
          open: isExpanded ? { height: 'auto', transition: { duration: 0.3 } }
            : { height: 0, transition: { duration: 0.3 } },
        }}
        className={styles.content}
      >
        <motion.div
          initial="initial"
          animate="open"
          exit="collapsed"
          variants={{
            initial: { opacity: 0 },
            collapsed: { opacity: 0, transition: { duration: 0.05 } },
            open: isExpanded
              ? { opacity: 1, transition: { duration: 0.3, delay: 0.2 } }
              : { opacity: 0, transition: { duration: 0.05 } },
          }}
        >
          <RichTextContent content={item?.content} />
        </motion.div>
      </motion.div>
    </div>
  );
}

Accordion.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
  }),
  isExpanded: PropTypes.bool,
  handleExpanded: PropTypes.func,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
};

Accordion.defaultProps = {
  item: {},
  isExpanded: false,
  handleExpanded: () => {},
  theme: 'light',
};

export default Accordion;
